<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="title"
        :subtitle="subTitle"
        :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de notificación de
          {{ module }}
        </vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <label for="recipient" class="w-full select-large">Destinatario</label>
          <vs-input
              id="recipient"
              name="recipient"
              v-model="notificationSettingsModel.recipient"
              v-validate="'required|email'"
              class="w-full"
              autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("recipient") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros módulo</vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Instituciones</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.institution_id"
                  :options="institutions"
                  :reduce="item => item.id"
                  label="name"
                  @input="getCategories(notificationSettingsModel.notifications_rules.entry.institution_id); getEntries()"
              />
            </div>
            <div class="vx-col md:w-2/2 w-full mt-5">
              <label for="recipient" class="w-full select-large">Categorías</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.category_id"
                  :options="categories"
                  :reduce="item => item.id"
                  label="name_generated"
                  @input="getEntries()"
              />
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Productos</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.id"
                  :options="entries"
                  :reduce="item => item.id"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros formulario</vs-divider>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="recipient" class="w-full select-large">Departamentos</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.form_data.department"
              :options="statesPrefilledList"
              :reduce="item => item.label"
              label="label"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToBack">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateNotificationSettings" :disabled="!validateForm">
                Guardar
              </vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import PrefilledListsClient from "../../utils/prefilledListsClient"
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient();
  const ENTRIES_CLIENT = new EntriesClient("products");
  const PREFILLED_LISTS_CLIENT = new PrefilledListsClient();
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("products");

  const requiredFields = ["recipient"];
  let dict = requiredFields.reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue] = {
          required: "* Este campo es requerido",
          email: "* Correo electrónico inválido"
        };
        return previousValue
      },
      {}
  );

  Validator.localize("es", {custom: dict});


  export default {

    data() {
      return {
        queryStrings: {
          institution_id:[],
          category_id:[]
        },
        title: "",
        subTitle: "",
        module: "",
        entry_type: "",
        categories: [],
        statesPrefilledList: [],
        institutions: [],
        entries: [],
        notificationSettingsModel: {
          recipient: "",
          notifications_rules: {
            entry: {
              institution_id: null,
              category_id: null,
              id: null,
            },
            form_data: {
              department: null,
            },
          }
        },
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.getPageTitle(true);
        this.loadNotificationSettingData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        })
      } else {
        this.getPageTitle(false);
        this.getStatesPrefilledList(false)
        this.getEntries(true)
            .then(() => {
              this.getInstitutions(false)
                  .then(() => {
                    this.$vs.loading.close();
                  })
            })
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
            && requiredFields.reduce((accumulator, currentValue) => {
              return accumulator && this.notificationSettingsModel[currentValue] !== null;
            });
      },
    },

    methods: {
      getPageTitle(update) {
        this.title = update ? `Editar destinatario de Productos` : `Crear destinatario de Productos`
        this.subTitle = "Configurar destinatario y los criterios para recibir notificaciones."
        this.module = "Productos"
        this.entry_type = "products"
      },

      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();

        let institution = this.notificationSettingsModel.notifications_rules.entry.institution_id
        let category = this.notificationSettingsModel.notifications_rules.entry.category_id

        this.queryStrings.institution_id = Array.isArray(institution) ? institution : []
        this.queryStrings.category_id =  Array.isArray(category) ? category : []

        await ENTRIES_CLIENT.all({queryStrings: this.queryStrings})
            .then(response => {
              this.cleanArrayEntries(
                  this.notificationSettingsModel.notifications_rules.entry.id,
                  response.data,
                  this.queryStrings
              )
              this.entries = response.data;

              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await INSTITUTIONS_CLIENT.all()
            .then(response => {
              this.institutions = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getCategories(institutions, showLoading = true) {
        if (Array.isArray(institutions) && institutions.length > 0) {
          if (showLoading) this.$vs.loading();

          let result = []
          for (let institution in institutions) {
            const selectedInstitution = this.institutions.find(el => el.id === institutions[institution])

            await ATTRIBUTE_OPTIONS_CLIENT.categories({
              pathParameters: {institutionId: selectedInstitution.id},
              queryStrings: {representation_type: "simple"}
            })
              .then(response => {
                if (showLoading) this.$vs.loading.close();

                response.data.map(item => {
                  item.name_generated = `(${selectedInstitution.name}) - ${item.name}`
                })

                Array.prototype.push.apply(result, response.data)
              })
              .catch(error => {
                if (showLoading) this.$vs.loading.close();
                notifier.notification("error");
              });
          }

          this.categories = result

          let categories = this.categories.map(el => el.id)

          this.notificationSettingsModel.notifications_rules.entry.category_id = this.cleanArray(
              this.notificationSettingsModel.notifications_rules.entry.category_id,
              categories
          )
        }else{
          this.categories = [];
          this.notificationSettingsModel.notifications_rules.entry.category_id = null;
        }
      },

      async getStatesPrefilledList(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await PREFILLED_LISTS_CLIENT.getStates()
            .then(response => {
              if (showLoading) this.$vs.loading.close();

              this.statesPrefilledList = response.data;
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async loadNotificationSettingData(id) {
        await NOTIFICATION_SETTINGS_CLIENT.retrieve(this.entry_type, id)
            .then(response => {
              this.getStatesPrefilledList(true)
              this.getInstitutions(true)
                  .then(() => {
                    this.getCategories(
                      response.data.notifications_rules.entry.institution_id,
                      true
                    )
                      .then(() => {
                        this.getEntries(true)
                          .then(() => {
                            Object.assign(this.notificationSettingsModel, response.data);
                          })
                      })
                  })
            })
            .catch(error => {
              notifier.notification("error");
            });
      },

      async createOrUpdateNotificationSettings() {
        let status_validation = true

        await this.$validator.validateAll().then(el => {
          status_validation = el
        })

        if(!status_validation) return false

        if (this.notificationSettingsModel.id !== null && this.notificationSettingsModel.id !== undefined && this.notificationSettingsModel.id !== "") {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.update(
              this.notificationSettingsModel, this.entry_type, this.$route.params.id
          )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToBack();
              })
              .catch(error => {
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        } else {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.create(this.notificationSettingsModel, this.entry_type)
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToBack();
              })
              .catch(error => {
                if(error.response.status === 403 &&  error.response.data.errors.recipient !== undefined){
                  this.errors.add({
                    field: 'recipient',
                    msg: `Ya existe un registro con el correo ${this.notificationSettingsModel.recipient} para ${this.module}`
                  })
                }
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      },

      goToBack() {
        this.$router.push({name: "notification-settings", params: {entry_type: this.entry_type}});
      },

      cleanModel() {
        let exclude = ["entry_type", "id", "unix_created_at", "unix_updated_at"]
        Object.keys(this.notificationSettingsModel).forEach(key => {
          if (exclude.includes(key)) {
            delete this.notificationSettingsModel[key];
          }
        })

        this.notificationSettingsModel.notifications_rules.entry = this.cleanNullKeys(
            this.notificationSettingsModel.notifications_rules.entry
        )

        this.notificationSettingsModel.notifications_rules.form_data = this.cleanNullKeys(
            this.notificationSettingsModel.notifications_rules.form_data
        )
      },

      cleanNullKeys(data){
        let newObject = {}

        for( let key in data ){
          if(data[key] !== null && data[key].length > 0){
            newObject[key] = data[key]
          }
        }
        return newObject;
      },

      cleanArray(selected_items, response) {
        if (Array.isArray(selected_items)) {
          return selected_items.filter(el => { if (response.includes(el)) return el} )
        }
        return selected_items
      },

      cleanArrayEntries(selected_items, response, queryString){
        if(Array.isArray(selected_items)){
          let array_selected = response.filter( el => { if(selected_items.includes(el.id))return el })
          array_selected = array_selected.map( el => {
            if(queryString.institution_id.includes(el.institution_id)){
              return el.id
            }else if(queryString.category_id.includes(el.category_id)){
              return el.id
            }
          })

          if(array_selected.includes(undefined)){
            this.notificationSettingsModel.notifications_rules.entry.id = null
            return false
          }

          this.notificationSettingsModel.notifications_rules.entry.id = array_selected
        }
      }
    },

    components: {
      FormWizard,
      TabContent,
      "v-select": vSelect,
    }
  };
</script>